@import '../../../variables';

.cta-quote {
  margin-bottom: 76px;

  .quote-card {
    text-align: center;
    margin-bottom: 30px;

    .img {
      img {
        width: 100%;
      }

      margin-bottom: 41px;
    }

    h3 {
      font-weight: normal;
      font-size: 36px;
      line-height: 1.28;
      margin-bottom: 11px;
    }

    p {
      margin-bottom: 33px;
    }
  }
}


@media (max-width: 991px) {
  .cta-quote {
    margin-bottom: 60px;

    .quote-card {
      .img {
        margin-bottom: 20px;
      }

      h3 {
        font-size: 30px;
        margin-bottom: 5px;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}
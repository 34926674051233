@import '../../../variables';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.breadcrumb {
  background-color: transparent;
  font-size: 12px;
  line-height: 1.58;
  padding: 0;
  margin-bottom: 40px;
  border-radius: 0;

  .breadcrumb-item {
    padding-left: 4px;

    &:first-child {
      padding-left: 0;
    }

    &.active {
      color: $color-gray-7;
    }

    &+.breadcrumb-item {
      &::before {
        color: $color-gray-7;
        padding-right: 4px;
      }
    }
  }
}

.product-content {
  padding-top: 10px;

  .product-gallery {
    height: 100%;
    padding-bottom: 40px;
  }

  .image-gallery {
    border: 1px solid $color-gray-1;
    padding: 7px;
    height: 100%;
  }

  .image-gallery-slide.center:focus {
    outline: none;
    height: 100%;
  }

  .image-gallery-content.right {
    height: 100%;
  }

  .image-gallery-slide-wrapper.right {
    width: calc(100% - 130px);
    height: 100%;
  }

  .image-gallery-thumbnails-wrapper.right {
    width: 130px;
    margin: 0;

    .image-gallery-thumbnails {
      padding-left: 7px;

      .image-gallery-thumbnail {
        border: 1px solid $color-gray-1;
        padding: 2px;

        &+ .image-gallery-thumbnail {
          border: 1px solid $color-gray-1;
          margin-top: 5px;
          padding: 2px;
        }
      }
    }
  }

  .image-gallery-swipe {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .image-gallery-thumbnail {
    width: 100%;
    border: none;

    &.active,
    &:hover,
    &:focus {
      border: none;
      transform: none;
    }
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    transform: none;
    top: -28px;
    right: -73px;
    left: auto;
    padding: 0;
    width: 16px;
    height: 10px;
    background-image: url('./img/icon-up-nav.png');
    filter: none;

    .image-gallery-svg {
      display: none;
    }
  }

  .image-gallery-right-nav {
    background-image: url('./img/icon-down-nav.png');
    bottom: -28px;
    top: auto;
  }
}

.product-short-info {
  h2 {
    font-weight: normal;
    font-size: 48px;
    line-height: 1.31;
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 27px;
  }

  table {
    margin-bottom: 40px;
  }
}

.map-projects {
  padding-top: 90px;

  .map-img {
    position: relative;
  }

  .cta {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 90px;

    h3 {
      font-weight: normal;
      font-size: 36px;
      line-height: 1.28;
      color: $color-white;
      margin-bottom: 32px;
    }
  }
}

.product-full-info {
  padding-top: 90px;

  .nav-tabs {
    border: none;

    .nav-link {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: -0.2px;
      color: $color-dark;
      border: none;
      border-bottom: 1px solid $color-gray-3;
      border-radius: 0;
      padding-top: 10px;
      padding-bottom: 8px;
      background-color: transparent;

      &.active {
        background-color: transparent;
        color: $color-base;
        border-bottom: 1px solid #FF8364;
      }
    }
  }

  .section-title {
    font-weight: normal;
    font-size: 36px;
    line-height: 1.28;
    color: $color-blue;
    margin: 0;
  }

  .description-text {
    p,
    li {
      font-size: 20px;
      line-height: 1.7;
      margin-bottom: 40px;
      color: $color-gray-4;
    }

    ol {
      padding-left: 22px;
      margin: 0;
    }

    li {
      margin-bottom: 0;
    }

    ul {
      list-style-type: disc;
      padding-left: 22px;
    }
  }

  .description-section {
    padding-top: 48px;

    .section-title {
      margin-bottom: 32px;
    }

    .description-text {
      &.hidden {
        height: 396px;
        overflow: hidden;
        position: relative;

        &::after {
          content: '';
          background: $white-gradient;
          width: 100%;
          height: 87px;
          position: absolute;
          bottom: -10px;
        }
      }
    }

    .details-link {
      margin-top: 22px;

      &.show-less {
        img {
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }
      }
    }

    .banner-text-link {
      position: relative;

      > img {
        width: 100%;
      }

      .cta {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 62px 23px 67px 27px;
        background: $blue-gradient;

        h3 {
          font-weight: normal;
          font-size: 36px;
          line-height: 1.28;
          color: $color-white
        }

        .details-link {
          color: $color-white;
        }
      }
    }

    .cta-catalog {
      margin-top: 90px;
      background-color: rgba($color-gray-1, .8);
      padding: 60px 0;

      .see-catalog {
        max-width: 1110px;
        margin-left: auto;
        margin-right: auto;
        padding: 31px 40px 31px 56px;
        background: $color-base;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        display: flex;
        align-items: center;

        h4 {
          font-size: 24px;
          line-height: 1.33;
          letter-spacing: -0.4px;
          color: $color-white;
          margin: 0;
        }

        .description {
          margin-left: auto;
          display: flex;
          align-items: center;

          p {
            color: $color-white;
            margin-right: 40px;
          }
        }
      }
    }
  }

  .details-section {
    padding-top: 90px;

    .section-title {
      margin-bottom: 37px;
    }

    .details-info {
      margin-bottom: 60px;
    }

    h4 {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: -0.4px;
      margin-bottom: 24px;
    }
  }

  .applications-section {
    padding-top: 30px;

    .section-title {
      margin-bottom: 60px;
    }

    .date {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: $color-base;
      margin-bottom: 17px;
    }

    .title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    h4 {
      max-width: 350px;
      font-weight: normal;
      font-size: 36px;
      line-height: 1.28;
      margin-bottom: 6px;
    }

    table {
      margin-bottom: 26px;
    }
  }

  .documentation-section {
    padding-top: 90px;

    .section-title {
      margin-bottom: 24px;
    }

    .documentation-cards-listing {
      margin-top: 60px;
      padding-bottom: 30px;
      margin-bottom: 110px;
      border-bottom: 1px solid $color-gray-8;

      .documentation-card {
        margin-bottom: 30px;
      }
    }
  }
}


@media (max-width: 1200px) {
  .product-short-info {
    h2 {
      font-size: 40px;
    }
  }

  .map-projects {
    .cta {
      h3 {
        font-size: 30px;
      }
    }
  }

  .product-full-info {
    .applications-section {
      h4 {
        font-size: 30px;
      }

      .title {
        img {
          max-width: 70px;
        }
      }
    }

    .documentation-section {
      .documentation-cards-listing {
        .documentation-card {
          padding: 20px;
        }
      }
    }
  }

  .documentation-card {
    h4 {
      font-size: 18px;
    }
  }
}

@media (max-width: 991px) {
  .product-short-info {
    h2 {
      font-size: 34px;
    }
  }

  .map-projects,
  .product-full-info,
  .product-full-info .details-section {
    padding-top: 60px;
  }

  .product-full-info {
    .nav-tabs {
      .nav-link {
        font-size: 18px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .description-section {
      .banner-text-link {
        .cta {
          padding: 30px 20px;

          h3 {
            font-size: 30px;
          }
        }
      }

      .cta-catalog {
        margin-top: 60px;
      }
    }

    .details-section {
      .details-info {
        margin-bottom: 40px;
      }
    }

    .applications-section {
      padding-top: 20px;

      .section-title {
        margin-bottom: 37px;
      }

      h4 {
        font-size: 28px;
      }
    }

    .documentation-section {
      padding-top: 60px;

      .section-title {
        margin-bottom: 37px;
      }

      .documentation-cards-listing {
        margin-bottom: 70px;
      }
    }
  }
}

@media (max-width: 767px) {
  .map-projects {
    .cta {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .product-full-info {
    .section-title {
      font-size: 30px;
    }

    .description-text {
      p, li {
        font-size: 18px;
      }

      p {
        margin-bottom: 20px;
      }
    }

    .nav-tabs {
      .nav-link {
        font-size: 16px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .description-section {
      .description-text {
        &.hidden {
          height: 386px;
        }
      }

      .banner-text-link {
        max-width: 320px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;

        .cta {
          padding: 50px 20px;
        }
      }

      .cta-catalog {
        padding: 40px 0;

        .see-catalog {
          padding: 20px 30px 25px;
          flex-wrap: wrap;

          h4 {
            width: 100%;
            margin-bottom: 20px;
          }

          .description {
            flex-wrap: wrap;

            p {
              width: 100%;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .applications-section {
      .date {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 576px) {
  .product-content {
    .image-gallery {
      height: auto;
    }

    .image-gallery-swipe {
      top: auto;
      transform: none;
    }

    .image-gallery-thumbnail {
      width: 80px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .image-gallery-left-nav,
    .image-gallery-right-nav {
      display: none;
    }
  }

  .product-short-info {
    h2 {
      font-size: 30px;
    }
  }

  .map-projects {
    .map-img {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 50%;
      overflow: hidden;
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 100%;
      max-width: initial;
    }

    .cta {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h3 {
        margin-bottom: 10px;
      }
    }
  }
}
@import 'variables.scss';

@font-face {
  font-family: 'Tahoma';
  src: url('resources/fonts/Tahoma-Bold.woff2') format('woff2'),
  url('resources/fonts/Tahoma-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Tahoma';
  src: url('resources/fonts/Tahoma.woff2') format('woff2'),
  url('resources/fonts/Tahoma.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// General Styles

* {
  font-family: 'Tahoma', serif;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: $color-dark;
}

p {
  margin: 0;
  color: $color-gray-4;
  line-height: 1.69;
}

b, strong {
  font-weight: bold;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.bullet-list {
  list-style-type: disc;
  padding-left: 20px;
}

a {
  color: $color-base;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
}

img {
  vertical-align: middle;
  max-width: 100%;
}

input {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.container-fluid {
  max-width: 1410px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  background-color: $color-action;
  box-shadow: none;
}

table.custom-table {
  color: $color-dark;
  margin-bottom: 0;

  tr {
    border-top: 1px solid $color-gray-3;
    border-bottom: 1px solid $color-gray-3;
  }

  td, th {
    border: none;
    padding: 0;
    height: 53px;
    vertical-align: middle;
  }

  .name {
    color: $color-gray-4;
  }

  .value {
    font-weight: bold;
    line-height: 1.5;
    color: $color-dark;
    text-align: right;
  }
}
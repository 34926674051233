@import "../../../../../variables";

.documentation-card {
  display: block;
  padding: 30px;
  background: $beige-gradient;
  border-radius: 10px;

  h4 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.4px;
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 21px;
  }
}
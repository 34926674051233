@import '../../../variables';

.main-btn {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  padding-top: 20px;
  padding-bottom: 22px;
  width: 266px;
  text-align: center;
  display: inline-block;
  background-color: $color-action;
  color: $color-white;

  &:hover {
    color: $color-white;
  }
}

.white-btn {
  background-color: $color-white;
  color: $color-base;

  &:hover {
    color: $color-base;
  }
}

.ask-offer {
  font-size: 14px;
  line-height: 17px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px 0 0 10px;
  width: 147px;
  padding: 18px 15px 15px;
  position: fixed;
  top: 45%;
  right: 0;
  z-index: 3;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
  }
}

.btn-with-icon {
  background-color: $color-white;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.4px;
  text-align: left;
  padding: 12px 0 13px 20px;
  width: 200px;
  color: $color-dark;
  position: relative;

  &:hover {
    color: $color-dark;
  }

  &::after {
    content: '';
    position: absolute;
    right: 22px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 14px;
    height: 14px;
  }
}

.play-btn {
  &::after {
    background-image: url("./img/icon-play.png");
  }
}

.download-btn {
  &::after {
    background-image: url("./img/icon-download.png");
  }
}

@media (max-width: 991px) {
  .main-btn {
    width: 213px;
    padding-top: 14px;
    padding-bottom: 15px;
  }

  .ask-offer {
    width: 147px;
    padding: 18px 15px 15px;
  }

  .btn-with-icon {
    font-size: 18px;
    padding-top: 8px;
    padding-bottom: 9px;
    width: 160px;
    padding-left: 15px;

    &::after {
      //right: 10px;
    }
  }
}

@media (max-width: 767px) {
  .ask-offer {
    width: 125px;
    padding: 15px 12px 12px;
  }
}
@import '../../../variables';

.categories-content {
  padding-top: 42px;

  .banner {
    margin-bottom: 30px;
    position: relative;

    > img {
      width: 100%;
    }

    .white-btn {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
  }

  .listing {
    margin-bottom: 70px;
  }
}

@media (max-width: 576px) {
  .categories-content {
    .banner {
      .white-btn {
        bottom: 15px;
        left: 15px;
      }
    }
  }
}
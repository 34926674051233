@import '../../../variables';

.product-card {
  display: block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
  margin-bottom: 30px;

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 76.5%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
    }
  }

  .data {
    text-align: center;
    padding: 20px 10px 24px;

    h5 {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: -0.2px;
      margin: 0;
    }
  }
}


@media (max-width: 767px) {
  .product-card {
    .data {
      h5 {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }
}
// Colors
$color-white: #ffffff;
$color-base: #0D4676;
$color-blue: #005989;
$color-dark: #141414;
$color-action: #DF001B;
$color-gray-1: #F3F3F3;
$color-gray-2: #F9F9F9;
$color-gray-3: #DEDEDE;
$color-gray-4: #616161;
$color-gray-5: #B9B9B9;
$color-gray-6: #F2F5F5;
$color-gray-7: #808080;
$color-gray-8: #ECECEC;;

// Gradients
$beige-gradient: linear-gradient(180deg, #EDEAE2 0%, #FAF8F4 100%);
$blue-gradient: linear-gradient(180deg, #121734 0%, rgba(60, 66, 99, 0) 100%);
$white-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
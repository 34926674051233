@import "../../../../../variables";

.footer-features {
  background-color: $color-blue;
  padding-top: 30px;
  padding-bottom: 24px;

  .feature {
    text-align: center;

    .img {
      height: 68px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 12px;
    }

    p {
      color: $color-gray-1;
      font-size: 12px;
      line-height: 1.58;
    }
  }
}

@media (max-width: 991px) {
  .footer-features {
    padding-bottom: 9px;

    .feature {
      margin-bottom: 15px;

      .img {
        margin-bottom: 5px;
      }
    }
  }
}
@import '../../../variables';

.main-footer {
  .footer-newsletter {
    background-color: $color-base;
    padding-top: 80px;
    padding-bottom: 20px;

    .newsletter-form {
      width: 82.2%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    .text {
      width: 43.1%;
      padding-right: 80px;
    }

    h2 {
      color: $color-white;
      font-weight: normal;
      font-size: 36px;
      line-height: 1.28;
      margin: 0;
    }

    form {
      width: 56.9%;
      padding-left: 20px;
      padding-top: 10px;
      position: relative;
    }

    .email-newsletter {
      background-color: $color-white;
      height: 58px;
      width: calc(100% - 180px);
      border: none;
      border-radius: 0;
      font-size: 24px;
      line-height: 1.5;
      letter-spacing: -0.4px;
      padding: 0 27px;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: rgba($color-gray-4, .6);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: rgba($color-gray-4, .6);
      }

      &::-ms-input-placeholder {
        color: rgba($color-gray-4, .6);
      }
    }

    .submit-btn {
      height: 58px;
      width: 180px;
      position: absolute;
      top: 10px;
      right: 0;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: $color-action;
      font-weight: bold;
      font-size: 20px;
      color: $color-white;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .info-acord {
      display: flex;
      margin-top: 12px;
      margin-bottom: 0;
    }

    .info-newsletter-text {
      width: calc(100% - 180px);
      color: $color-gray-1;
      margin-bottom: 0;
    }

    .checkbox-acord {
      width: 180px;
      padding-left: 22px;
      min-height: auto;

      label {
        font-size: 12px;
        line-height: 1.58;
        color: $color-gray-1;
        margin: 0;

        &::before {
          width: 14px;
          height: 14px;
          border-radius: 0;
          outline: none;
          border: none;
          box-shadow: none;
          background-color: $color-white;
        }
      }
    }

    .custom-control-input:checked~.custom-control-label::before {
      border: none;
      background-color: $color-white;
    }

    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
      background-image: url('./img/icon-check.png');
      background-position: center;
      width: 18px;
      height: 18px;
      top: 2px;
      left: -26px;
    }
  }

  .footer-info {
    .footer-top {
      padding-top: 45px;
      padding-bottom: 50px;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-gray-1;

      .logo {
        width: 18.5%;
        padding-right: 15px;
      }

      .footer-widgets {
        width: 81.5%;
        padding-left: 15px;
      }
    }

    .nav {
      justify-content: space-between;
      margin-bottom: 50px;

      .nav-item {
        .nav-link {
          line-height: 1.69;
          color: $color-gray-4;
          padding: 0;
        }
      }

    }

    .widget-area {
      display: flex;
      justify-content: space-between;

      .widget {
        width: 454px;
        max-width: 48%;

        h6 {
          font-size: 16px;
          line-height: 1.5;
          padding-bottom: 9px;
          margin-bottom: 13px;
          position: relative;

          &::after {
            content: '';
            width: 150px;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $color-gray-1;
          }
        }

        a {
          line-height: 1.69;
          color: $color-gray-4;
        }

        .social-icons {
          margin-top: 16px;
        }
      }
    }

    .copyright {
      padding-top: 15px;
      padding-bottom: 17px;

      p {
        font-size: 12px;
        line-height: 1.58;
      }
    }
  }
}



@media (max-width: 1200px) {
  .main-footer {
    .footer-newsletter {
      .newsletter-form {
        width: 95%;
      }

      .text {
        padding-right: 15px;
      }
    }

    .footer-info {
      .nav {
        flex-wrap: wrap;
        justify-content: flex-start;

        .nav-item {
          .nav-link {
            padding-right: 15px;
          }
        }
      }
    }
  }
}


@media (max-width: 991px) {
  .main-footer {
    .footer-newsletter {
      .text {
        width: 35%;
      }

      form {
        width: 65%;
      }

      h2 {
        font-size: 30px;
      }

      .email-newsletter {
        font-size: 20px;
        padding: 0 15px;
        width: calc(100% - 150px);
        height: 50px;
      }

      .submit-btn {
        width: 150px;
        height: 50px;
        font-size: 16px;
      }

      .info-acord {
        margin-top: 7px;
      }

      .info-newsletter-text {
        width: calc(100% - 150px);
        padding-right: 10px;
      }

      .checkbox-acord {
        width: 150px;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-footer {
    .footer-newsletter {
      padding-top: 50px;
      padding-bottom: 50px;

      .newsletter-form {
        flex-wrap: wrap;

        .text {
          width: 100%;
          padding-right: 0;
          margin-bottom: 10px;
        }

        form {
          width: 100%;
          padding-left: 0;
        }
      }
    }

    .footer-info {
      .footer-top {
        .logo {
          width: 100%;
          margin-bottom: 25px;
        }

        .footer-widgets {
          padding-left: 0;
          width: 100%;
        }
      }

      .nav {
        margin-bottom: 30px;

        .nav-item {
          width: auto;

          .nav-link {
            font-size: 14px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .main-footer {
    .footer-newsletter {
      .email-newsletter {
        width: 100%
      }

      .submit-btn {
        position: relative;
        top: 0;
        margin-top: 15px;
      }

      .info-acord {
        flex-wrap: wrap;
      }

      .info-newsletter-text {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }

      .checkbox-acord {
        width: 100%;
      }
    }

    .footer-info {
      .footer-top {
        padding-bottom: 15px;
      }

      .widget-area {
        flex-wrap: wrap;

        .widget {
          width: 100%;
          max-width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }
}
@import "../../../variables";


.social-icons {
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    padding: 0 8px;
  }

  li {
    &:last-child {
      a {
        padding-right: 0;
      }
    }

    &:first-child {
      a {
        padding-left: 0;
      }
    }
  }
}
@import '../../../variables';

.filters {
  .accordion {
    > .card {
      border: none;
      border-radius: 0;
      margin-bottom: 8px;

      &.active {
        .card-header {
          background-color: $color-base;

          &::after {
            background-image: url('./img/icon-up.png');
          }

          h3 {
            color: $color-white;
          }
        }
      }
    }

    .card-header {
      border-bottom: none;
      margin: 0;
      background-color: $color-gray-6;
      padding: 17px 23px;
      cursor: pointer;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 23px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 10px;
        background-image: url('./img/icon-down.png');
      }

      h3 {
        font-size: 16px;
        line-height: 1.5;
        color: $color-base;
        padding-right: 30px;
        margin: 0;
      }
    }

    .card-body {
      padding: 0 0 22px;

      .group {
        margin-top: 17px;
        padding-right: 47px;
        padding-left: 23px;

        h4 {
          font-weight: normal;
          font-size: 12px;
          line-height: 1.58;
          color: $color-gray-4;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          position: relative;

          &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $color-gray-5;
            flex: 1;
            margin-left: 16px;
          }
        }

        .custom-control {
          min-height: auto;
          padding-left: 0;
          margin-bottom: 4px;

          .custom-control-input {
            width: 27px;
            height: 27px;
          }

          .custom-control-input:checked~.custom-control-label::after {
            background-image: url('./img/icon-check.png');
          }
        }

        .custom-control-label {
          font-size: 16px;
          line-height: 1.62;
          padding-left: 48px;
          color: $color-base;
          cursor: pointer;
          min-height: 27px;

          &::before {
            width: 27px;
            height: 27px;
            left: 0;
            border-radius: 0;
            border: none;
            background-color: $color-gray-1;
            box-shadow: none;
          }

          &::after {
            width: 27px;
            height: 27px;
            left: 0;
            border-radius: 0;
            border: none;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .filters {
    .accordion {
      .card-header {
        padding: 10px;

        &::after {
          right: 10px;
        }

        h3 {
          font-size: 14px;
        }
      }

      .card-body {
        .group {
          margin-top: 13px;
          padding-left: 15px;
          padding-right: 15px;

          .custom-control-label {
            font-size: 14px;
            padding-left: 36px;
          }
        }
      }
    }
  }
}
@import '../../../variables';

.details-link {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  color: $color-base;
  display: flex;
  align-items: center;

  img {
    margin-left: 12px;
  }
}
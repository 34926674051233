@import '../../../variables';

.main-header {
  .navbar {
    padding: 0;
    border-top: 1px solid $color-gray-1;
    border-bottom: 1px solid $color-gray-1;
    height: 61px;

    .nav-link {
      padding: 22px 15px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.4px;
      color: $color-base;


      &:hover,
      &.active {
        background-color: $color-base;
        color: $color-white;
      }
    }
  }
}

.top-nav {
  .top-nav-elements {
    display: flex;
    align-items: flex-end;
  }

  .logo {
    padding-bottom: 40px;
  }

  .search-form {
    margin-left: auto;
    padding-bottom: 40px;

    form {
      position: relative;
    }

    .form-control {
      width: 390px;
      height: 44px;
      border: 1px solid $color-gray-3;
      border-radius: 0;
      padding-left: 15px;
      padding-right: 50px;
      font-size: 20px;
      letter-spacing: -0.2px;

      &::placeholder {
        color: rgba($color-gray-4, .6);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: rgba($color-gray-4, .6);
      }

      &::-ms-input-placeholder {
        color: rgba($color-gray-4, .6);
      }

      &:focus {
        box-shadow: none;
      }
    }
    
    .submit-button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 44px;
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .header-phone {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .phone {
        img {
          margin-right: 15px;
        }

        .phone-no {
          font-weight: bold;
          font-size: 14px;
          line-height: 1.17;
          letter-spacing: 0.2px;
          vertical-align: middle;
        }

        span {
          font-weight: bold;
          font-size: 12px;
          line-height: 1.17;
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }

        .show-phone {
          letter-spacing: -0.2px;
          line-height: 1.37;
          margin-left: 27px;
        }
      }

      .languages {
        border: 1px solid $color-gray-3;
        width: 95px;
        text-align: center;

        img {
          margin-right: 8px;
          display: inline-block;
        }

        p {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.4px;
          color: $color-gray-5;
          display: inline-block;
          padding-top: 3px;
          padding-bottom: 4px;
        }
      }
    }
  }

  .companies {
    background-color: $color-gray-2;
    text-align: center;
    padding: 12px 58px 17px;
    margin-left: 36px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 18px;
      right: 12px;
      width: 16px;
      height: 10px;
      background-image: url('./img/icon-down.png');
    }

    p {
      font-weight: bold;
      font-size: 17px;
      line-height: 1.29;
      color: $color-base;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 1400px) {
  .main-header {
    .navbar {
      .nav-link {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .main-header {
    .navbar {
      .nav-link {
        font-size: 13px;
        padding-left: 7px;
        padding-right: 7px;
        letter-spacing: 0;
      }
    }

    .social-icons {
      a {
        padding: 0 3px;

        img {
          max-width: 15px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .main-header {
    .navbar {
      position: relative;

      .navbar-toggler {
        padding: 0;
        border: none;

        &:focus {
          outline: none;
        }
      }

      .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
      }

      .logo {
        img {
          max-height: 34px;
        }
      }

      .navbar-collapse {
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        padding-bottom: 20px;
        background-color: $color-white;
        z-index: 4;
      }

      .nav-link {
        font-size: 14px;
        letter-spacing: 0.4px;
        padding: 10px 15px;
      }

      .social-icons {
        padding: 10px 15px;

        a {
          padding: 0 8px;

          img {
            max-width: 100%;
          }
        }

        li {
          &:first-child {
            a {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .top-nav {
    .search-form {
      margin-left: 0;
    }

    .companies {
      margin-left: auto;
    }
  }
}

@media (max-width: 767px) {
  .top-nav {
    .top-nav-elements {
      align-items: center;
    }

    .search-form {
      padding-bottom: 0;

      .header-phone {
        .phone {
          img {
            margin-right: 5px;
          }

          .show-phone {
            margin-left: 10px;
            font-size: 15px;
          }
        }
      }

      .form-control {
        width: 320px;
        height: 40px;
        font-size: 18px;
      }

      .submit-button {
        height: 40px;
      }
    }

    .companies {
      > img {
        max-height: 60px;
      }
    }
  }
}

@media (max-width: 576px) {
  .top-nav {
    .search-form {
      padding-right: 10px;

      .header-phone {
        flex-wrap: wrap;
        margin-bottom: 5px;

        .phone {
          width: 100%;
        }
      }

      .form-control {
        height: 35px;
        width: 100%;
        padding-right: 5px;
        font-size: 16px;
        padding-left: 10px;
        padding-right: 40px;
      }

      .submit-button {
        width: 40px;
        height: 35px;
      }
    }

    .companies {
      padding: 9px 20px 14px;

      &::after {
        right: 5px;
        -webkit-background-size: 70%;
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: top right;
      }

      p {
        font-size: 15px;
      }
    }
  }
}